<template> 
  <Link /> 
</template> 

<script> 
  import Link from '../components/Link' 
  export default { 
    components: { 
      Link 
    } 
  } 
</script> 
