<template> 
  <About /> 
</template> 

<script> 
  import About from '../components/About' 
  export default { 
    components: { 
      About 
    } 
  } 
</script> 
