import Vue from 'vue'; 
import Router from 'vue-router'; 
import Home from './views/Home.vue'; 
import About from './views/About.vue'; 
import Link from './views/Link.vue'; 

Vue.use(Router); 

const routes = [
    { 
      path: '/', 
      name: 'home', 
      component: Home,
      meta: {desc: 'タイトル生成ページです。'},
    }, 
    { 
      path: '/about', 
      name: 'about', 
      component: About, 
      meta: {title: 'About', desc: '本Webアプリ説明ページです。'},
    }, 
    { 
      path: '/link', 
      name: 'link', 
      component: Link, 
      meta: {title: 'Link', desc: '本Webアプリに関連するリンク一覧です。'},
    }, 
  ];

const router = new Router({
  base: process.env.BASE_URL,
  routes
});

export default router;