<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="onHome">Home</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="onAbout">About</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="onLink">Link</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>"小説家になろう"っぽい小説タイトルメーカー</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app>
      <v-card-text>
        <p>© 2021 Satoru Katsumata</p>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },

  data: () => ({
    drawer: false,
  }),

  methods:{
    createTitleDesc: function(routeInstance){
      if(routeInstance.meta.title){
        var setTitle = routeInstance.meta.title + '| "小説家になろう"っぽい小説タイトルメーカー';
        document.title = setTitle;
      } else {
        document.title = '"小説家になろう"っぽい小説タイトルメーカー'
      }

      if(routeInstance.meta.desc){
        var setDesc = routeInstance.meta.desc + '| GPT-2を使用した小説タイトル生成Webアプリです。"小説家になろう"に投稿されていそうなタイトルを生成します。';
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      } else {
        document.querySelector("meta[name='description']").setAttribute('content', 'description is not set')
      }
    },
    onHome() {
      if(this.$route.path != '/'){
        this.$router.push({path: '/'});
      }
    },
    onAbout() {
      if(this.$route.path != '/about'){
        this.$router.push({path: 'about'});
      }
    },
    onLink() {
      if(this.$route.path != '/link'){
        this.$router.push({path: 'link'});
      }
    },
  },
  mounted : function(){
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  watch:{
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance);
    }
  }
};
</script>
