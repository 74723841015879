<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row justify="center" align-content="center" align="center">
          <v-col>
            <v-card elevation="2">
              <v-card-title>生成したタイトル：</v-card-title>
              <v-card-text>
                <div class="text-h4 text--primary">
                  {{ this.title }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align-content="center" align="center">
          <v-col>
            <v-btn :loading="loading" :disabled="loading" @click="getTitle" elevation="2">
              タイトル生成!
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>ご注意事項</h3>
            <ul>
              <li>本サイトの出力は、機械学習モデルの出力であり、内容がよくわからない場合などが考えられます。<br>
                  作者は生成されたタイトルについて一切の責任を負いかねますのであらかじめご了承ください。
              </li>
              <li>学習データには実際に存在するタイトルを用いています。<br>
               そのため、実在する小説タイトルを生成する可能性があります。<br>
               生成されたタイトルのご利用にはご注意ください。
              </li>
              <li>機械学習モデルの未知語は全て<b>[未知語]</b>と表現しています。<br>
                <b>[未知語]</b>が生成された場合は、適宜脳内補完をお願いいたします。
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    title: '',
    loading: false,
  }),
  methods: {
    getTitle() {
      this.loading = true;
      const narou_gpt2_api_url =
        "https://narou-title-generator-api.herokuapp.com/sampleTitle";
      axios.get(narou_gpt2_api_url)
        .then((response) => (this.title = response.data.title,
                             this.loading = false));
    }
  }
}
</script>
